<script setup>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
</script>

<template>
  <div class="tcpa-container">
    <p>
      <label><input type="hidden" id="leadid_tcpa_disclosure"/>
        By clicking "Submit", I confirm I have read and agreed to this website's <a href="/terms-and-conditions/">Terms and Conditions</a> and <a href="/privacy-policy/">Privacy Policy</a>,which includes a mandatory arbitration provision, and provide my express written consent via electronic signature authorizing OpenHome and our <a href="/marketing-partners/">Marketing Partners</a> to contact me about home improvement and other related products and services to the number and email address I provided (including any wireless number). I further expressly consent to receive telemarketing emails, calls, text messages, pre-recorded messages, and artificial voice messages via an autodialed phone system, even if my telephone number is a mobile number that is currently listed on any state, federal or corporate “Do Not Call” list. I understand that my consent is not a condition of purchase of any goods or services and that standard message and data rates may apply.
        <a href="/">Openhomepros.com</a> is a free service to assist homeowners in connecting with local service providers. All contractors/providers are independent and <a href="/">openhomepros.com</a> does not warrant or guarantee any work performed. It is the responsibility of the homeowner to verify that the hired contractor furnishes the necessary license and insurance required for the work being performed. All persons depicted in a photo or video are actors or models and not contractors listed on <a href="/">openhomepros.com</a>.
        My consent may be revoked at any time.
      </label>
    </p>
  </div>
</template>

<style lang="scss" scoped>
  .tcpa-container {
    p {
      text-align: justify;
      font-size: 12pt;
      line-height: 18px;
      color: black;
      margin: 10px;
      a {
        color: #18469d;
      }
    }
  }

  @media (max-width: 490px) {
    .tcpa-container p {
      margin: 10px 0;
    }
  }
</style>
